import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// 1. 引入你需要的组件
import { 
    Toast,
    Button, 
    NavBar,
    Form, 
    Field, 
    Cell, 
    CellGroup,
    Grid, 
    GridItem, 
    Tag, 
    CountDown, 
    Tab, 
    Tabs, 
    Collapse, 
    CollapseItem, 
    Divider, 
    Uploader, 
    Watermark, 
    FloatingPanel, 
    Empty, 
    NoticeBar, 
    BackTop,
    Picker,
    Popup,
    ActionSheet,
    Badge,
    Skeleton,
    SkeletonTitle,
    SkeletonImage,
    SkeletonAvatar,
    SkeletonParagraph,
} from 'vant'
// 2. 引入组件样式
import 'vant/lib/index.css'

const app = createApp(App)

app.use(Toast)
app.use(Button)
app.use(NavBar)
app.use(Form)
app.use(Field)
app.use(Cell)
app.use(CellGroup)
app.use(Grid)
app.use(GridItem)
app.use(Tag)
app.use(CountDown)
app.use(Tab)
app.use(Tabs)
app.use(Collapse)
app.use(CollapseItem)
app.use(Divider)
app.use(Uploader)
app.use(Watermark)
app.use(FloatingPanel)
app.use(Empty)
app.use(NoticeBar)
app.use(BackTop)
app.use(Picker)
app.use(Popup)
app.use(ActionSheet)
app.use(Badge)
app.use(Skeleton)
app.use(SkeletonTitle)
app.use(SkeletonImage)
app.use(SkeletonAvatar)
app.use(SkeletonParagraph)

import API from '@/api'

app.config.globalProperties.$API = API

app.use(router)

app.mount('#app')