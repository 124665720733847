import request from '@/utils/request'

export const getLoginUrl = (data) => {
    return request({
      url: '/front/login/url',
      method: 'post',
      data
    })
}

export const doLogin = (data) => {
  return request({
    url: '/front/login/login',
    method: 'post',
    data
  })
}