import request from '@/utils/request'

export const getPostDetail = (data) => {
    return request({
      url: '/front/post/detail',
      method: 'post',
      data
    })
}

export const submitForm = (data) => {
  return request({
    url: '/front/post/apply',
    method: 'post',
    data
  })
}