import { createRouter,createWebHashHistory, createWebHistory} from "vue-router"

const post = () => import("../views/post/post")
const loginCallback = () => import("../views/login/callback")


const routes = [
    {
        path: "/post/:id",
        name: "post",
        component: post
    },
    {
        path: "/login/callback",
        name: "loginCallback",
        component: loginCallback
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
    },
    // 404 page must be placed at the end !!!
    { path: '/:catchAll(.*)', redirect: '/404' }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
  })

export default router